import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import CiQuizzUs from "../components/conversational-index/usa/ci-questionnaire/ci-quizz";


import SEO from "../components/seo";


class QuestionnairePage extends Component {

    render(){

        return(
            <>
                <SEO title="Conversational Maturity Index: Evaluate your Company's Conversational Maturity." image="https://info.iadvize.com/hubfs/CI%20-%202021/Thumbnail%20-%20Conversational%20Index%20(3).png" lang="en" description="By completing the online version of this audit, consisting of 19 simple questions, you will get an objective overview of your conversational maturity in less than 10 minutes."/>
                <CiQuizzUs/>
            </>
        )
    }

}

export default QuestionnairePage