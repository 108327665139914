import React, { useEffect, useRef, useState } from "react";

import { Modal, Button, Toast } from 'react-bootstrap';

import CiQuizzStyle from "./ci-quizz.module.scss";
import { pilliarData } from "./../../../../datas/datas-us";
import { navigate } from "gatsby";


const CiQuizzUs = (props) => {


    const [show, setShow] = useState(false);

    const [indexQuestion, setIndexQuestion] = useState(0);
    const [indexPillier, setIndexPillier] = useState(0);

    const [nullAnswer, setNullAnswer] = useState(0);

    const [email, setEmail] = useState("");
    const [industrie, setIndustrie] = useState("");
    const [website, setWebsite] = useState("");
    const [job, setJob] = useState("");

    const handleEmail = (event) => setEmail(event.target.value);
    const handleIndustrie = (event) => setIndustrie(event.target.value);
    const handleWebsite = (event) => setWebsite(event.target.value);
    const handleJob = (event) => setJob(event.target.value);

    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => setShowToast(!showToast);

    const [showToastDisclaimer, setShowToastDisclaimer] = useState(true);
    const toggleToastDisclaimer = () => setShowToastDisclaimer(!showToastDisclaimer);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [screenPreQualification, setScreenQualification] = useState(true);

    const handlePreQualification = () => setScreenQualification(false);

    const [resultatsArray, updateResultatsArray] = useState([]);

    const resettingRef = useRef(false);

    const increasePillier = () => {
        setIndexPillier(indexPillier + 1)
    }

    const decreasePillier = () => {
        setIndexPillier(indexPillier - 1)
    }

    const decreaseQuestion = () => {
        setIndexQuestion(indexQuestion - 1);
    }

    const increaseQuestion = () => {

        if((indexPillier + 1 == 3) && ((indexQuestion + 1) == 3)){
            
            setIndexQuestion(indexQuestion + 1);

        } else if((indexQuestion + 1) == 3 || ((indexPillier + 1 == 3) && ((indexQuestion + 1) == 4))) {
            
            increasePillier()
            setIndexQuestion(0);

        } else{

            setIndexQuestion(indexQuestion + 1);

        }
        
    }

    const increaseLastQuestion = () => {
        setIndexQuestion(indexQuestion + 1);
    }

    const rollBack = () => {
        if(indexQuestion == 0){

            if(indexPillier == 3)
            {
                setIndexQuestion(3);
            } else 
            {
                setIndexQuestion(2);
            }

            setIndexPillier(indexPillier - 1);

        } else{

            setIndexQuestion(indexQuestion - 1);
        }

        updateResultatsArray(resultatsArray.filter((element, index) => index < resultatsArray.length - 1));
    }


    const addAnswer = (id) => {

        resettingRef.current = true;

        if(id == 0){
            setNullAnswer(nullAnswer+1)
            
            if(nullAnswer >= 3){
                setShow(true);
            }
            
            updateResultatsArray(resultatsArray.concat(id));

            if(pilliarData[indexPillier].questions[indexQuestion].id == 19){
                increaseLastQuestion();
            } else{
                increaseQuestion();
            }
            
        } else {
            updateResultatsArray(resultatsArray.concat(id));

            if(pilliarData[indexPillier].questions[indexQuestion].id == 19){
                increaseLastQuestion();
            } else{
                increaseQuestion();
            }
        }

    }

    useEffect(() => {

        console.log("immutable data changed");
        console.log(resultatsArray);

        if(resettingRef.current){
          resettingRef.current = false;

            console.log(resultatsArray.length);

            
            if(pilliarData[indexPillier].questions[indexQuestion].id == 20){
                navigate(
                    "/en/resultat",
                    {
                        state: { resultatsArray }
                    }
                );
            }
          
        }
    },[resultatsArray])


    const sendDataToHubspot = () => {
        console.log("le mail est : " + email);
        console.log("l'industrie est : " + industrie);
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"fields":[{"name":"email","value":email},{"name":"consent_checkbox","value":"true"},{"name":"jobtitle","value":job},{"name":"website","value":website},{"name":"ci_industry","value":industrie}],"context":{"pageUri":"www.conversational-index.com/fr/","pageName":"Conversational Index - Landing Page - Form menu"}}),
            redirect: 'follow'
        };
        fetch('https://api.hsforms.com/submissions/v3/integration/submit/403167/1f945562-c2ee-4649-b6ed-c592b3d41b02', requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .then(handleClose)
        .then(toggleToast)
        .catch(error => console.log('error', error));
    }


    return (
        <>

            <div
                style={{
                    position: 'fixed',
                    top: 10,
                    left: 10,
                    zIndex: 99
                }}
            >

                <Toast show={showToastDisclaimer} onClose={toggleToastDisclaimer}>
                    <Toast.Header>
                        <strong>Disclaimer</strong>
                    </Toast.Header>
                    <Toast.Body>
                    “By using this service, you agree not to try to obtain the methodology and ponderation hereinafter offered by iAdvize for commercial use against iAdvize’s interests. This service is only to be used as a mean to assess companies positioning against their competitors in the field of conversational performances, and is protected under several US and europeans laws. iAdvize and its subsidiaries reserves the right to prosecute any abuse, violations and/or misappropriation of the CONVERSATIONAL MATURITY INDEX. iAdvize and its subsidiaries are under no condition liable of this index effect on user’s or third party’s reputation whatsoever.”
                    </Toast.Body>
                </Toast>


            </div>

            <section className={ CiQuizzStyle.preQualification } style={{ display: screenPreQualification ? 'block': 'none'}}>
                <div className={ CiQuizzStyle.inner }>
                    
                    <div className={ CiQuizzStyle.idzLogo }>
                        powered by <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Large.png" alt=""/>
                    </div>

                    <div className={ CiQuizzStyle.questionQualification }>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Property%201=Primaire.png" alt="" />
                        <p>Assess your conversational maturity online. Be honest, do not lie to yourself as you want to know the truth about your conversational strategy.</p>
                        <h1>
                        Our customers can ask questions on our website, mobile site or application via messaging ?
                            <span>(live chat and/or messaging apps)</span>
                        </h1>
                        <div>
                            <button onClick={ handlePreQualification }>Yes</button>
                            <button onClick={ handleShow }>No</button>
                        </div>
                    </div>

                    <img id={ CiQuizzStyle.logoFooter } src="https://info.iadvize.com/hubfs/CI%20-%202021/Title.png"></img>
                </div>

                
            </section>

            <section className={ CiQuizzStyle.quizz } style={{ display: pilliarData[indexPillier].questions[indexQuestion].id != 20 && !screenPreQualification ? 'block': 'none'}}>
        <div className={ CiQuizzStyle.inner }>
            <div className={ CiQuizzStyle.alpha }>
                <div className={ CiQuizzStyle.top } style={{background: pilliarData[indexPillier].colorMain }}>
                    <h2>
                        { pilliarData[indexPillier].name } 
                    </h2>
{/*                     <div className={ CiQuizzStyle.legend }>
                        { pilliarData[indexPillier].description } 
                    </div> */}
                </div>    
                <div className={ CiQuizzStyle.bottom } style={{background: pilliarData[indexPillier].colorSecond }}>
                    <img src={ pilliarData[indexPillier].illustration }></img>
                </div>    
            </div>
            <div className={ CiQuizzStyle.beta }>


                <div className={ CiQuizzStyle.mordor }>

                    <div className={ CiQuizzStyle.top}>
                        <span>Question { pilliarData[indexPillier].questions[indexQuestion].id }</span>
                        <div className={ CiQuizzStyle.categorie }>
                            { pilliarData[indexPillier].name }
                        </div>
                        <div className={ CiQuizzStyle.question }>
                            { pilliarData[indexPillier].questions[indexQuestion].name }
                        </div>
                    </div>

                    <div className={ CiQuizzStyle.bottom}>
                        <div className={ CiQuizzStyle.answers }>
                            <button onClick={() => addAnswer(1)} className={ CiQuizzStyle.answer } id={ pilliarData[indexPillier].questions[indexQuestion].hubspotValue }>{ pilliarData[indexPillier].questions[indexQuestion].answers[0].name }</button>                        
                            <button onClick={() => addAnswer(2)} className={ CiQuizzStyle.answer } id={ pilliarData[indexPillier].questions[indexQuestion].hubspotValue }>{ pilliarData[indexPillier].questions[indexQuestion].answers[1].name }</button>                        
                            <button onClick={() => addAnswer(3)} className={ CiQuizzStyle.answer } id={ pilliarData[indexPillier].questions[indexQuestion].hubspotValue }>{ pilliarData[indexPillier].questions[indexQuestion].answers[2].name }</button>                        
                            <button onClick={() => addAnswer(4)} className={ CiQuizzStyle.answer } id={ pilliarData[indexPillier].questions[indexQuestion].hubspotValue }>{ pilliarData[indexPillier].questions[indexQuestion].answers[3].name }</button>                        
                        </div>
                        <button onClick={() => addAnswer(0)} className={ CiQuizzStyle.pass } href="#">I don't know</button>
                        <button onClick={() => rollBack()} className={ CiQuizzStyle.previous } disabled={ indexPillier == 0 && indexQuestion == 0 } href="#"> ← Previous question</button>
                    </div>

                </div>
            </div>

            <div className={ CiQuizzStyle.progression }>
                <div className={ CiQuizzStyle.set }>
                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Property%201=Primaire.png" alt="" />
                    <div className={ CiQuizzStyle.right }>

                        <div className={ CiQuizzStyle.barInner }>
                            <div className={ CiQuizzStyle.bar }>
                                <div className={ CiQuizzStyle.follow } style={{width: pilliarData[indexPillier].questions[indexQuestion].percentProgress }}>
                                </div>
                            </div>

                            <span>
                                { pilliarData[indexPillier].questions[indexQuestion].id }/19
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

        <div
            style={{
                position: 'fixed',
                top: 10,
                right: 10,
                zIndex: 99
            }}
        >
                <Toast show={showToast} onClose={toggleToast} delay={5000} autohide>
                    <Toast.Header>
                        <strong>Thank you!</strong>
                    </Toast.Header>
                    <Toast.Body>You will receive an email with your area and areas of improvement.</Toast.Body>
                </Toast>

        </div>

        <Modal  
        className="modalMain"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        >
        <Modal.Body className="modalBody">
            <img onClick={handleClose} src="https://info.iadvize.com/hubfs/CI%20-%202021/Group%20865.png"></img>
            <h3 className="modalTitle">Entrust</h3>
            <p className="modalLegend">your evaluation to our teams</p>

            <div className="formInner">
                <form action="" method="get" className="formLate">

                    <div className="formSet">
                        <span>Industry*</span>
                        <select id="industries" name="industries" value={industrie} onChange={handleIndustrie} required>
                            <option value="Retail & CPG">Retail & CPG</option>
                            <option value="Finance - Health Insurance">Finance - Health Insurance</option>
                            <option value="Automotive">Automotive</option>
                            <option value="Telco & Consumer Services">Telco & Consumer Services</option>
                            <option value="Travel & Tourism">Travel & Tourism</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <div className="formSet">
                        <span>Job*</span>
                        <input type="text" name="job" id="job" value={job} onChange={handleJob} placeholder="Job title" required/>
                    </div>

                    <div className="formSet">
                        <span>Website*</span>
                        <input type="text" name="website" id="website" value={website} onChange={handleWebsite} placeholder="website" required/>
                    </div>

                    <div className="formSet">
                        <span>Email adress*</span>
                        <input type="email" name="email" id="email" value={email} onChange={handleEmail} placeholder="Professional email*" required/>
                    </div>

                    <div className="formSet">
                        <div className="rgpd">
                            <input type="checkbox" id="scales" name="scales" required/>
                            <label>I have read and accept <a href="https://privacy.iadvize.com/fr/">the iAdvize privacy policy.</a></label>
                        </div>
                    </div>

                    <div className="formSet">
                        <Button id="goToLead" className="ctaFinal" onClick={sendDataToHubspot} disabled={!email || !website || !industrie }>
                            Entrust your evaluation to our teams
                        </Button>
                    </div>

                </form>
            </div>

        </Modal.Body>
        </Modal>
        </>
    )

}

export default CiQuizzUs

