export const pilliarData = [
    {
        name: "Quality - Expertise, Empathy & Authenticity",
        description: "Ce qui fait qu'une conversation est utile, pertinente, agréable pour le client, qu'elle répond à ses attentes et bien plus encore.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20876.png",
        hubspotValue: "ci_p1",
        colorMain: "#128434",
        colorSecond: "#1BC74E",
        questions: [
            {
                name: "When a question is asked via messaging: ",
                hubspotValue: "ci_p1_q1",
                id: '1',
                percentProgress: '6%',
                answers: [
                    {
                        name: "A chatbot answers all questions.  No human involved",
                        score: "1"
                    },
                    {
                        name: "Agents answer all the questions. They are not specialized on any scope in particular.",
                        score: "2"
                    },
                    {
                        name: "A chatbot welcomes the customer and answers basic questions. If needed, an agent takes over the conversation.",
                        score: "3"
                    },
                    {
                        name: "Each question is answered by the most appropriate respondent (chatbot, agent or expert) depending on the topic (care, product advice, product family, etc.).",
                        score: "4"
                    }
                ]
            },
            {
                name: "When our customers ask a question via messaging related to a particular product or service: ",
                hubspotValue: "ci_p1_q2",
                id: '2',
                percentProgress: '11%',
                answers: [
                    {
                        name: "Our agents or chatbots look for information in our online product catalog only.",
                        score: "1"
                    },
                    {
                        name: "Our agents look for information in a product catalog or other sources, like the manufacturer's website.",
                        score: "2"
                    },
                    {
                        name: "Besides product information, our agents can request extra help and expertise internally or externally.",
                        score: "3"
                    },
                    {
                        name: "In addition to responding to product related questions, our agents are trained to give additional information and make product recommendations according to customers’ usage.",
                        score: "4"
                    }
                ]
            },
            {
                name: "When we answer our customers via messaging : ",
                hubspotValue: "ci_p1_q3",
                id: '3',
                percentProgress: '17%',
                answers: [
                    {
                        name: "Agents are free to answer in their own style. We do not provide them with guidelines.",
                        score: "1"
                    },
                    {
                        name: "There is a set of canned answers that has to be used. / Or: The only respondent is a bot.",
                        score: "2"
                    },
                    {
                        name: "Agents are advised to use our canned answers. Some agents provide their own feedback and tone if appropriate.",
                        score: "3"
                    },
                    {
                        name: "Beside canned answers, agents are trained to share their own experience and find the right tone while remaining professional.",
                        score: "4"
                    }
                ]
            },

        ]
    },
    {
        name: "Scalability",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20875.png",
        hubspotValue: "ci_p2",
        colorMain: "#2958E5",
        colorSecond: "#25A2FA",
        questions: [
            {
                name: "Our customers can reach us via messaging: ",
                hubspotValue: "ci_p2_q1",
                id: '4',
                percentProgress: '22%',
                answers: [
                    {
                        name: "Only during business hours.",
                        score: "1"
                    },
                    {
                        name: "A chatbot answers whenever they ask a question.",
                        score: "2"
                    },
                    {
                        name: "Our agents answer during business hours and a chatbot answers out of business hours.",
                        score: "3"
                    },
                    {
                        name: "Our agents answer during business hours. Customers can leave a message when agents are not available or chat with a bot.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Our customers can engage with us in real time messaging conversations: ",
                hubspotValue: "ci_p2_q2",
                id: '5',
                percentProgress: '28%',
                answers: [
                    {
                        name: "During business hours. When agents are not immediately available, our customers are placed in queue.",
                        score: "1"
                    },
                    {
                        name: "During business hours and when agents are immediately available. If an agent is not immediately available, we make sure the customer is not waiting more than 30 seconds in queue.",
                        score: "2"
                    },
                    {
                        name: "24/7. Our agents answer during business hours. Outside business hours, bots answer as much as they can.",
                        score: "3"
                    },
                    {
                        name: "24/7. Our agents answer during business hours. Outside business hours, independent experts take over to ensure 24/7 availability. In addition, customers can chat with a bot.",
                        score: "4"
                    }
                ]
            },
            {
                name: "My company’s vision concerning chatbots:  ",
                hubspotValue: "ci_p2_q3",
                id: '6',
                percentProgress: '33%',
                answers: [
                    {
                        name: "Bots do not have any added value in responding to our customers questions.",
                        score: "1"
                    },
                    {
                        name: "Chatbots replace agents. They handle all messaging conversations with our customers. ",
                        score: "2"
                    },
                    {
                        name: "Chatbots replace agents as much as possible. They handle all incoming conversations and if needed, they transfer to agents.",
                        score: "3"
                    },
                    {
                        name: "Chatbots complete agents. They welcome customers and qualify their needs when necessary, before connecting them with an agent.",
                        score: "4"
                    }
                ]
            },

        ]
    },
    {
        name: "Business Drive",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20871.png",
        hubspotValue: "ci_p3",
        colorMain: "#F7AA4D",
        colorSecond: "#FFCD40",
        questions: [
            {
                name: "How important is messaging in your corporate strategy? ",
                hubspotValue: "ci_p3_q1",
                id: '7',
                percentProgress: '39%',
                answers: [
                    {
                        name: "We launched messaging because our competitors do it.",
                        score: "1"
                    },
                    {
                        name: "It is part of our innovation priorities. Messaging and chatbots are trendy. We launched messaging to stay innovation leaders.",
                        score: "2"
                    },
                    {
                        name: "It is part of our business priorities. Our conversational strategy generates value, additional turnover and improves cost of operations.",
                        score: "3"
                    },
                    {
                        name: "It is part of our strategic priorities. Our conversational strategy is part of our DNA and supporting transformations within our company.",
                        score: "4"
                    }
                ]
            },
            {
                name: "How are messaging channels managed and monitored within your organization? ",
                hubspotValue: "ci_p3_q2",
                id: '8',
                percentProgress: '44%',
                answers: [
                    {
                        name: "There is no dedicated team. The Head of Customer Relations has no specific focus on messaging. ",
                        score: "1"
                    },
                    {
                        name: "There is a team dedicated to messaging. The Head of Customer Relations is paying a growing attention to messaging. ",
                        score: "2"
                    },
                    {
                        name: "There is a team dedicated to messaging. Their results are being reported to the Chief Customer Officer or similar positions.",
                        score: "3"
                    },
                    {
                        name: "Our organization is messaging-centric. The Chief Customer Officer focuses mainly on messaging.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Which indicators are you following in order to measure the performance of your conversational strategy?",
                hubspotValue: "ci_p3_q3",
                id: '9',
                percentProgress: '50%',
                answers: [
                    {
                        name: "Productivity and quality indicators as well as customer satisfaction. ",
                        score: "1"
                    },
                    {
                        name: "Productivity and quality indicators, customer satisfaction and business performance indicators such as turnover after conversations, conversion rate and average order value.",
                        score: "2"
                    },
                    {
                        name: "Productivity, quality and business performance indicators as well as operational cost optimization (e.g. cost reduction of customer support).",
                        score: "3"
                    },
                    {
                        name: "In addition to productivity, quality, business performance indicators and operational cost optimization, long-term KPIs like repurchase rate or CLV.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Do you gather feedback and insights on and via your conversational strategy?",
                hubspotValue: "ci_p3_q4",
                id: '10',
                percentProgress: '56%',
                answers: [
                    {
                        name: "Messaging is used to assist customers only.  ",
                        score: "1"
                    },
                    {
                        name: "We test different conversational strategies and compare their impact.",
                        score: "2"
                    },
                    {
                        name: "We analyze the customer journey to identify frictions and adjust our conversational strategy accordingly.",
                        score: "3"
                    },
                    {
                        name: "We analyze conversations and extract insights in order to enhance our customer journeys, processes and offering.",
                        score: "4"
                    }
                ]
            }

        ]
    },
    {
        name: "Personalized engagement",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20(5).png",
        hubspotValue: "ci_p4",
        colorMain: "#128434",
        colorSecond: "#1BC74E",
        questions: [
            {
                name: "Our customers can engage with us via messaging... ",
                hubspotValue: "ci_p4_q1",
                id: '11',
                percentProgress: '61%',
                answers: [
                    {
                        name: "on one web page only (e.g. Contact us).",
                        score: "1"
                    },
                    {
                        name: "on support pages (e.g. FAQ) and account sections.",
                        score: "2"
                    },
                    {
                        name: "on product pages and within the funnel in order to optimize the turnover.",
                        score: "3"
                    },
                    {
                        name: "on all pages on our website.",
                        score: "4"
                    }
                ]
            },
            {
                name: "We engage with our customers via messaging...",
                hubspotValue: "ci_p4_q2",
                id: '12',
                percentProgress: '67%',
                answers: [
                    {
                        name: "when they reach out to us.",
                        score: "1"
                    },
                    {
                        name: "after a set amount of time on our website.",
                        score: "2"
                    },
                    {
                        name: "proactively at critical steps or with behavioral targeting.",
                        score: "3"
                    },
                    {
                        name: "when the customer reaches out to us or proactively when we identify a potential need for advice.",
                        score: "4"
                    }
                ]
            },
            {
                name: "Our customers can engage via messaging...",
                hubspotValue: "ci_p4_q3",
                id: '13',
                percentProgress: '72%',
                answers: [
                    {
                        name: "on our desktop website only.",
                        score: "1"
                    },
                    {
                        name: "on our website and parts of our mobile site.",
                        score: "2"
                    },
                    {
                        name: "equally on our mobile and desktop website.",
                        score: "3"
                    },
                    {
                        name: "on our website on all devices and on at least one messaging platform (e.g. SMS, WhatsApp, Facebook Messenger…).",
                        score: "4"
                    }
                ]
            }
        ]
    },
    {
        name: "Rich & User Friendly Messaging",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20(6).png",
        hubspotValue: "ci_p5",
        colorMain: "#2958E5",
        colorSecond: "#25A2FA",
        questions: [
            {
                name: "How does messaging integrate into your website experience? ",
                hubspotValue: "ci_p5_q1",
                id: '14',
                percentProgress: '78%',
                answers: [
                    {
                        name: "It stands apart. Messaging pops up in a separate window.",
                        score: "1"
                    },
                    {
                        name: "The chatbox integrates into the webpage, but we cannot customize it to match our brand universe (color, font, tone of voice).",
                        score: "2"
                    },
                    {
                        name: "It totally integrates into our website as we want it to be part of our UX.",
                        score: "3"
                    },
                    {
                        name: "It totally integrates into our website. Moreover, agents can view customers navigation on our website to assist them better.",
                        score: "4"
                    }
                ]
            },
            {
                name: "How does the messaging experience integrate into your mobile site experience?",
                hubspotValue: "ci_p5_q2",
                id: '15',
                percentProgress: '83%',
                answers: [
                    {
                        name: "We open messaging in another tab. We do not want the messaging box to interfere with browsing experience.",
                        score: "1"
                    },
                    {
                        name: "It opens on the same page. The customer can minimize the chatbox to keep focusing on site content.",
                        score: "2"
                    },
                    {
                        name: "It opens on the same page. A notification pops up when a new message is received while the chatbox is minimized / closed.",
                        score: "3"
                    },
                    {
                        name: "It opens on the same page. The customer gets notified when he receives an answer even after leaving the website. ",
                        score: "4"
                    }
                ]
            },
            {
                name: "What kind of content can be shared within the conversations with your customers?",
                hubspotValue: "ci_p5_q3",
                id: '16',
                percentProgress: '89%',
                answers: [
                    {
                        name: "Our conversations are text only. We do not support emojis, hyperlinks, images or documents.",
                        score: "1"
                    },
                    {
                        name: "We support rich text: we can send and receive emojis, and links.",
                        score: "2"
                    },
                    {
                        name: "We support rich text, and we can also send and receive documents and images.",
                        score: "3"
                    },
                    {
                        name: "We support rich text, documents and images. In addition, we can escalate from messaging to voice or video call.",
                        score: "4"
                    }
                ]
            }
        ]
    },
    {
        name: "Omnichannel and Seamlessness",
        description: "C'est ce qui rend la conversation possible pour tout client à tout moment de son parcours.",
        illustration: "https://info.iadvize.com/hubfs/CI%20-%202021/Group%20(7).png",
        hubspotValue: "ci_p6",
        colorMain: "#F7AA4D",
        colorSecond: "#FFCD40",
        questions: [
            {
                name: "When our customers re-engage with us via messaging... ",
                hubspotValue: "ci_p6_q1",
                id: '17',
                percentProgress: '94%',
                answers: [
                    {
                        name: "the agent does not have access to past conversations.",
                        score: "1"
                    },
                    {
                        name: "the agent can get access to past conversations in parallel to the current conversation.",
                        score: "2"
                    },
                    {
                        name: "past conversations are automatically shown in the agent interface when the conversation starts.",
                        score: "3"
                    },
                    {
                        name: "agents and customers can resume a conversation seamlessly. ",
                        score: "4"
                    }
                ]
            },
            {
                name: "Our customers can reach us via…",
                hubspotValue: "ci_p6_q2",
                id: '18',
                percentProgress: '97%',
                answers: [
                    {
                        name: "messaging on our website.",
                        score: "1"
                    },
                    {
                        name: "messaging and another channel such as video call, web callback, social media or third-party messaging apps (WhatsApp, ABC, Facebook Messenger…).",
                        score: "2"
                    },
                    {
                        name: "messaging and at least five other channels such as video call, web callback, social media or third part messaging apps (WhatsApp, ABC, Facebook Messenger…). ",
                        score: "3"
                    },
                    {
                        name: "at least five different channels but also through other innovations initiating messaging conversations such as QR codes or call to messaging functionalities.",
                        score: "4"
                    }
                ]
            },
            {
                name: "To what extent does your messaging strategy drive your omnichannel transformation?",
                hubspotValue: "ci_p6_q3",
                id: '19',
                percentProgress: '100%',
                answers: [
                    {
                        name: "We’re using messaging for our digital experience only.",
                        score: "1"
                    },
                    {
                        name: "We sometimes give information about our product availability in store and such via messaging.",
                        score: "2"
                    },
                    {
                        name: "We promote the use of messaging in our stores and our agents are able to drive sales in store.",
                        score: "3"
                    },
                    {
                        name: "We use messaging to drive both online and offline sales and customer experience. In-Store associates can answer to customers online.",
                        score: "4"
                    }
                ]
            },
            {
                name: "",
                hubspotValue: "ci_p6_q3",
                id: '20',
                percentProgress: '100%',
                answers: [
                    {
                        name: "",
                        score: "1"
                    },
                    {
                        name: "",
                        score: "2"
                    },
                    {
                        name: "",
                        score: "3"
                    },
                    {
                        name: "",
                        score: "4"
                    }
                ]
            }
        ]
    }

  ];